import React from "react";

import Layout from "../components/layout";
import PurpleRow from "../components/purpleRow";
import SingleOpinion from "../components/singleOpinion";
import { opinions } from "../data/opinions";

const OpinionsPage = () => {
  return (
    <Layout title="Opinie">
      <div className="wrapper">
        <div className="section section-presentation">
          <div className="container">
            <div className="row">
              <div className="opinions-main-wrapper">
                <div className="col-sm-12 opinions-main-text">
                  <h2>Przeczytaj opinie właścicieli, dyrektorów i rodziców</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section opinions-page">
        <div className="container">
          <div className="row">
            {opinions.map((element) => {
              const opinion = element.node;
              return (
                <SingleOpinion
                  key={opinion.id}
                  author={opinion.author}
                  place={opinion.place}
                  image={opinion.image.childImageSharp.fluid.src}
                >
                  {opinion.opinion}
                </SingleOpinion>
              );
            })}
          </div>
        </div>
        {/*<div className="load-more">*/}
        {/*  <div className="load-more-wrapper">*/}
        {/*    <div className="load-more-button-wrapper">*/}
        {/*      <button className="violet-btn btn join-btn">Zobacz więcej</button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      <PurpleRow>
        Cokolwiek robisz,
        <br /> możesz to zrobić w kidsup
      </PurpleRow>
    </Layout>
  );
};

export default OpinionsPage;
