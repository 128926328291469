import React from "react";

const SingleOpinion = ({ children, author, place, image }) => (
  <div className="col-md-4 col-xs-12 col-sm-6" style={{ height: "700px" }}>
    <div className="card card-small-margin">
      <div className="main-blog-teaser">
        <div
          className="opion-single-photo"
          style={{
            borderRadius: "30px",
            maxHeight: "300px",
            width: "100%",
            backgroundImage: `url(${image})`,
          }}
        />
        <div className="opinion-card-text">{children}</div>
        <div className="opinion-author">
          <span className="bold-author">{author} </span>– {place}
        </div>
      </div>
    </div>
  </div>
);

export default SingleOpinion;
